import { useEffect, useState } from "react"

import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import metadata from "metadata.json"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"

import api from "api"
import { HybridLoginType } from "api/resources/users/types"
import UserInfo from "components/auth/UserInfo"
import LoadingOverlay from "components/shared/LoadingOverlay"
// import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import { getAuthRoute, getPlatform } from "utils/auth"
import { Platforms, Product } from "utils/constants"
import { CaptchaType, useGRecaptcha } from "utils/hooks/useGRecaptcha"
import toast from "utils/toast"

import AuthLayout from "./Layout"

type FormData = {
  identifier: string
}

type Props = {
  product: Product
}

const ForgotPassword = ({ product }: Props) => {
  const [searchParams] = useSearchParams()

  const platform =
    getPlatform(searchParams.get("platform")) ?? Platforms.student

  const prefillData =
    searchParams.get("email") ||
    searchParams.get("phoneNumber") ||
    searchParams.get("identifier")

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      identifier: prefillData || "",
    },
  })

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<HybridLoginType | null>(null)

  const getUserDetails = async (identifer: string) => {
    const res = await api.users.getUserDetails({
      data: {
        userId: identifer,
        product,
      },
    })
    if (res.isSuccessful) {
      setUser(res.data)
      return res.data
    }
    setLoading(false)
    if (res.errors.message) {
      toast.error(res.errors.message)
    }
    if (res.errors.fieldErrors?.userId) {
      setError("identifier", { message: res.errors.fieldErrors.userId })
    }
    if (res.errors.fieldErrors?.countryCode) {
      setError("identifier", { message: res.errors.fieldErrors.countryCode })
    }
  }

  const { captcha, resetCaptcha, run, RenderCaptcha } = useGRecaptcha("auth")

  const forgotPassword = async (identifer: string) => {
    if (!captcha) {
      toast.error("Please verify the captcha")
      return
    }

    setLoading(true)
    const data = await getUserDetails(identifer)
    if (data) {
      const res = await api.users.forgotPassword({
        data: {
          captcha,
          uuid: data?.uuid,
          product,
          /**
           * TODO: Send platform here after discussion with Chirag & Sukhvinder
           * Beacuse, one product can correspond to multiple platforms.
           * So, after resetting, in order to redirect the user back to their original portal, I need the platform.
           * Right now I will just redirect to the most probable platform that the user might have come from.
           * Check ResetPassword file for this code.
           */
        },
      })
      resetCaptcha()
      if (res.isSuccessful) {
        navigate(
          getAuthRoute({
            key: "login",
            product,
          }),
          {
            replace: true,
            state: {
              bannerText: `Instructions to reset your password has been sent to you on ${
                data?.email ?? "your email"
              }.`,
            },
          }
        )
        setLoading(false)
      } else {
        if (res.errors.fieldErrors?.invalidCaptcha) {
          resetCaptcha()
          run(CaptchaType.checkbox)
          toast.error("Verification failed, please try again later")
        }
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (prefillData) {
      forgotPassword(prefillData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefillData])

  const onSubmit = handleSubmit(formData => {
    forgotPassword(formData.identifier)
  })

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <AuthLayout platform={platform}>
      <ReactHelmet data={metadata.forgotPassword} />
      <form className="flex flex-col" onSubmit={onSubmit}>
        {user && (
          <UserInfo
            fullName={user.fullName}
            id={getValues("identifier")}
            picture={user?.profile?.picture}
          />
        )}
        <Typography className="mb-3" component="h1" variant="title1">
          Forgot Password?
        </Typography>
        <Typography className="mb-3" color="onSurface.800">
          Enter the email address or phone number you used when you joined and
          we’ll send you instructions to reset your password to the email linked
          to your account.
        </Typography>
        <TextField
          error={Boolean(errors.identifier)}
          helperText={errors.identifier?.message}
          label="Email or Phone Number"
          autoFocus
          fullWidth
          {...register("identifier", {
            required: { value: true, message: "Required" },
          })}
        />

        <RenderCaptcha />

        <Button
          className="mt-2"
          loading={isSubmitting || loading}
          type="submit"
          fullWidth
        >
          Send Reset Instructions
        </Button>
      </form>
    </AuthLayout>
  )
}

export default ForgotPassword

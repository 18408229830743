import React from "react"

import { Error, ErrorType } from "@suraasa/error-pages"
import { useNavigate } from "react-router"

import { routes } from "utils/routes"

import Navbar from "./Navbar"

const ErrorPage = ({ errorCode }: Pick<ErrorType, "errorCode">) => {
  const navigate = useNavigate()
  switch (errorCode) {
    case 401:
      return (
        <Error
          errorCode={401}
          navbar={<Navbar />}
          navigate={navigate}
          options={{ onLogin: () => navigate(routes.login) }}
        />
      )
    case 404:
      return (
        <Error
          errorCode={404}
          navbar={<Navbar />}
          navigate={navigate}
          options={{
            onHelp: () => navigate(routes.help),
            onHomepage: () => navigate(routes.profile),
          }}
        />
      )
    case 500:
      return (
        <Error
          errorCode={500}
          navbar={<Navbar />}
          navigate={navigate}
          options={{
            onHelp: () => navigate(routes.help),
          }}
        />
      )
    default:
      return null
  }
}

export default ErrorPage

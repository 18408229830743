import camelCase from "lodash/camelCase"
import { createSearchParams } from "react-router-dom"

import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import { BROWSER_STORAGE_KEYS, Platforms, Product } from "utils/constants"

import { routes } from "./routes"
import { LocalStorage } from "./storageFactory"

export function saveAuthInfo(authInfo: AuthData) {
  const newAuthInfoObj: AuthData = transformKeys(authInfo, camelCase)
  LocalStorage.setItem(
    BROWSER_STORAGE_KEYS.auth,
    JSON.stringify(newAuthInfoObj)
  )
}

export function getAuthInfo() {
  const authInfo = LocalStorage.getItem("auth")

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

export function clearAuthInfo() {
  const { auth } = BROWSER_STORAGE_KEYS
  LocalStorage.removeItem(auth)
  sessionStorage.removeItem(auth)
}

export const isValidPlatform = (
  platform: string | null,
  validPlatformList = Object.values(Platforms)
): platform is Platforms =>
  platform ? validPlatformList.includes(platform as Platforms) : false

export const getPlatform = (
  platform: string | null | undefined,
  validPlatformList?: Platforms[]
) => {
  if (!platform) return null

  return isValidPlatform(platform, validPlatformList) ? platform : null
}

export const getProbablePlatform = (
  product: Product,
  platform?: Platforms | null
) => {
  switch (product) {
    case Product.learning:
      if (!platform) return Platforms.student
      return platform
    case Product.school:
      if (!platform) return Platforms.school
      return platform
    case Product.partner:
      if (!platform) return Platforms.olympiadPartner
      return platform
    case Product.internal:
      if (!platform) return Platforms.student
      return platform
    case Product.center:
      if (!platform) return Platforms.centreAdmin
      return platform

    default:
      throw new Error("This shouldn't be reached")
  }
}

export function getAuthRoute(options: {
  search?: Record<string, string | string[]>
  product: Product
  platform?: Platforms | null
  key: "login" | "forgotPassword"
}) {
  options.search = options.search ?? {}
  const platform = getProbablePlatform(options.product, options.platform)

  const { key } = options

  let route: string = routes[key]

  switch (platform) {
    case Platforms.student:
      route = routes[key]
      break

    case Platforms.studentJobs:
      route = routes[key]
      options.search.platform = Platforms.studentJobs
      break
    case Platforms.centerHead:
      route = routes.centre[key]
      options.search.platform = Platforms.centerHead
      break

    case Platforms.centreAdmin:
      route = routes.centre[key]
      options.search.platform = Platforms.centreAdmin
      break

    case Platforms.professor:
    case Platforms.iqa:
      route = routes[key]
      options.search.platform = Platforms.professor
      break

    case Platforms.serviceAdmin:
      route = routes.internal[key]
      options.search.platform = Platforms.serviceAdmin
      break

    case Platforms.svp:
      route = routes.svp[key]
      options.search.platform = Platforms.svp
      break

    case Platforms.school:
      route = routes.school[key]
      options.search.platform = Platforms.school
      break

    case Platforms.apiDocs:
      route = routes.internal[key]
      options.search.platform = Platforms.apiDocs
      break

    case Platforms.olympiadPartner:
      route = routes.partner[key]
      options.search.platform = Platforms.olympiadPartner
      break

    case Platforms.applicationFormAdmin:
      route = routes.internal[key]
      options.search.platform = Platforms.applicationFormAdmin
      break

    default:
      throw new Error(
        `Invalid platform supplied to getAuthRoute. Platform: ${platform}; Product: ${options.product}`
      )
  }

  const searchParams = createSearchParams(options.search).toString()

  return `${route}?${searchParams}`
}

export const isValidOrigin = (origin: string) => {
  const isSuraasaURL = (url: URL) => {
    const [TLD, domain] = url.hostname.split(".").reverse()
    return domain === "suraasa" && TLD === "com"
  }

  const isNetlifyURL = (url: URL) => {
    const [TLD, domain] = url.hostname.split(".").reverse()
    return domain === "netlify" && TLD === "app"
  }

  const isLocalhostURL = (url: URL) => {
    const [TLD, domain] = url.hostname.split(".").reverse()
    return TLD === "localhost" && !domain
  }

  if (process.env.REACT_APP_ALLOW_LOCALHOST_SSO_MESSAGING === "true") {
    return (
      isLocalhostURL(new URL(origin)) ||
      isSuraasaURL(new URL(origin)) ||
      isNetlifyURL(new URL(origin))
    )
  }

  return isSuraasaURL(new URL(origin))
}

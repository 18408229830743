import React, { PropsWithChildren } from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import Navbar from "components/shared/Navbar"
import { Platforms } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  root: {
    minHeight: "calc(100vh - 62px)",
    position: "relative",
    "& .width-full": {
      width: "100%",
    },
  },
  leftContainer: {
    height: "100%",
    width: "45%",
    zIndex: "3",
    [theme.breakpoints.down("sm")]: {
      height: "30%",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "25%",
      width: "100%",
    },
  },
  rightContainer: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "max-content",
    },
  },
  background: {
    backgroundColor: "#F1F5F9",
    height: "100%",
    width: "45%",
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "30%",
      minHeight: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: "25%",
      minHeight: 225,
    },
  },
}))

const PartnerAuthLayout = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSm = useMediaQuery(theme.breakpoints.only("sm"))

  document.body.style.background = "white"

  return (
    <>
      <Navbar gutterBottom={false} platform={Platforms.olympiadPartner} />

      <div>
        {/* @ts-ignore image-error */}
        <div
          className={classes.background}
          style={{
            backgroundImage: `url(/assets/partner-login.png)`,
          }}
        />
      </div>
      <Container className={classes.root}>
        <div
          className="flex flex-col md:flex-row md:justify-between items-center"
          style={{ height: "100%" }}
        >
          <div
            className={clsx(
              classes.leftContainer,
              "flex flex-col gap-4 md:gap-8 justify-center md:items-start"
            )}
          >
            <Typography
              color="decorative.two.200"
              textAlign={isSm ? "center" : "left"}
              variant="largeTitle"
            >
              Sign In
            </Typography>
            <Typography
              color="decorative.two.200"
              textAlign={isSm ? "center" : "left"}
            >
              Enter your credentials to access your account
            </Typography>
          </div>
          <div
            className={clsx(
              classes.rightContainer,
              "mt-7 md:mt-0 flex justify-center items-center md:px-7"
            )}
          >
            {children}
          </div>
        </div>
      </Container>
    </>
  )
}

export default PartnerAuthLayout

import { useEffect, useState } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import api from "api"
import {
  clearAuthInfo,
  getAuthInfo,
  isValidPlatform,
  saveAuthInfo,
} from "utils/auth"
import { Platforms, Product } from "utils/constants"
import { GA, GA_EVENTS } from "utils/googleAnalytics"
import { trackUser } from "utils/helpers"
import { routes } from "utils/routes"

type Props = {
  options: {
    initialLoading?: boolean
    processRedirect?: boolean
  }
  product: Product
  platform: Platforms | null
  onSuccess?: (code: string) => void
}

export const useLogin = ({ options, product, platform, onSuccess }: Props) => {
  // We want to process redirect-url by default
  if (options.processRedirect === undefined) {
    options.processRedirect = true
  }

  const [loading, setLoading] = useState(options.initialLoading || false)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const processRedirectURL = (code: string) => {
    const redirectUrl = searchParams.get("redirect-url")

    if (redirectUrl) {
      try {
        const url = new URL(redirectUrl)
        url.searchParams.append("code", code)
        window.location.href = url.href
      } catch {
        console.error("> redirect-url not found. Redirecting to profile")
        navigate(routes.profile)
      }
    } else {
      console.error("> redirect-url not found. Redirecting to profile")
      navigate(routes.profile)
    }
  }
  const generateAuthCode = async (platformKey: Platforms) => {
    setLoading(true)
    const res = await api.users.generateAuthCode({
      data: { platform: platformKey },
    })
    if (res.isSuccessful) {
      const { code } = res.data

      if (onSuccess) {
        onSuccess(code)
      }
      if (options.processRedirect) {
        processRedirectURL(code)
      }
    } else {
      setLoading(false)
    }
  }

  const handleSubmit = async (data: any) => {
    // We logout the user if the user is already logged in with a different role
    if (getAuthInfo()) {
      await api.users.logout()
      clearAuthInfo()
    }

    const res = await api.users.login({
      data: { ...data, email: data.email.trim(), product },
    })
    if (res.isSuccessful) {
      saveAuthInfo({
        ...res.data,
        platform: platform ?? Platforms.student,
      })
      trackUser(res.data)
      GA.trackEvent(GA_EVENTS.login, {})
      if (platform) {
        await generateAuthCode(platform)
      } else {
        const redirectUrl = searchParams.get("redirect-url")

        if (redirectUrl) {
          try {
            const url = new URL(redirectUrl)
            window.location.replace(url.toString())
          } catch {
            navigate(routes.profile)
          }
        } else navigate(routes.profile)
      }
    } else {
      return res.errors
    }
  }

  useEffect(() => {
    const handleLogin = () => {
      const auth = getAuthInfo()
      /**
       * We allow user to open Student sign in page if they provide platform=Student.
       * If they don't then we just redirect them to the appropriate portal according to the platform present
       * in the `auth` object.
       */
      if (auth) {
        if (platform) {
          if (isValidPlatform(auth.platform)) {
            return generateAuthCode(platform)
          }
        }
        if (!platform) {
          switch (auth?.platform) {
            case Platforms.school:
              // See index.tsx line 17
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              window.location.href = process.env.REACT_APP_SCHOOL_PLATFORM_URL!
              return null
            case Platforms.svp:
              // See index.tsx line 17
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              window.location.href = process.env.REACT_APP_SVP_PLATFORM_URL!
              return null
            case Platforms.olympiadPartner:
              // See index.tsx line 17
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              window.location.href = process.env.REACT_APP_PARTNER_PLATFORM_URL!
              return null
            default:
              // platform is missing or invalid then we redirect to profile.
              navigate(routes.profile)
            // TODO: Add sentry log here
          }
        }
      }
      setLoading(false)
    }

    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { handleSubmit, loading }
}

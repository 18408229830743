type Hansel = {
  logEvent: (key: string, category: string, data: any) => void
  getUser: () => {
    putAttribute: (key: string, data: any) => void
  }
  track: (eventName: string, data?: any) => void
  internal: any
  registerListener: (fn: (eventName: string, data: any) => void) => void
}

export interface CustomWindow extends Window {
  Hansel?: Hansel
  smartech: any
}

declare let window: CustomWindow

export const getSmartech = (): any =>
  typeof window === "undefined" ? null : window.smartech

export const NetCore = {
  getHansel(): Hansel | null {
    if (typeof window !== "undefined" && window.Hansel) {
      return window.Hansel
    }
    return null
  },
  logEvent(key: string, data: any) {
    const hansel = this.getHansel()
    if (hansel) {
      hansel.logEvent(key, "GA", data)
    }
  },
  putAttribute(key: string, data: any) {
    const hansel = this.getHansel()
    if (hansel) {
      hansel.getUser().putAttribute(key, data)
    }
  },
}

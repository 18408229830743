import { Navigate, Outlet } from "react-router-dom"

import { getAuthInfo } from "utils/auth"
import {
  Platforms,
  SCHOOL_PLATFORM_URL,
  SVP_PLATFORM_URL,
} from "utils/constants"

function PrivateRoute() {
  const auth = getAuthInfo()

  const getChildren = () => {
    switch (auth?.platform) {
      case Platforms.school:
        window.location.href = SCHOOL_PLATFORM_URL
        return null
      case Platforms.svp:
        window.location.href = SVP_PLATFORM_URL
        return null
      default:
        return <Outlet />
    }
  }
  return auth ? getChildren() : <Navigate to="/" />
}

export default PrivateRoute

import React from "react"

import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import { useParams } from "react-router"

import api from "api"
import { saveAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"

import AuthLayout from "./Layout"

type FormData = {
  newPassword: string
  confirmPassword: string
}

type Props = {
  redirectUrl: string
  platform: Platforms
}

function CreatePassword({ redirectUrl, platform }: Props) {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const newPassword = watch("newPassword")
  const { uuid, token, timestamp } =
    useParams<{ uuid: string; token: string; timestamp: string }>()

  const onSubmit = handleSubmit(async data => {
    if (!uuid || !token || !timestamp) {
      return
    }
    const res = await api.users.resetPassword({
      data: {
        ...data,
        login: true,
      },
      urlParams: {
        uuid,
        token,
        timestamp,
      },
    })
    if (res.isSuccessful) {
      toast.success("Your password has been created successfully")
      if (res.data) {
        saveAuthInfo({ ...res.data, platform })
        window.location.href = redirectUrl
      }
    } else {
      handleErrors(setError, res.errors)
    }
  })

  return (
    <AuthLayout platform={platform}>
      <div>
        <Typography className="mb-0.5" variant="title2">
          Create Password
        </Typography>
        <Typography className="mb-3" color="onSurface.500" variant="body">
          Create Password for your Suraasa Account
        </Typography>
        <form className={clsx("flex flex-col gap-2 mt-3")} onSubmit={onSubmit}>
          <TextField
            autoComplete="password"
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword?.message}
            label="password"
            type="password"
            fullWidth
            {...register("newPassword", {
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
              onChange: () => clearErrors("confirmPassword"),
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            autoComplete="password"
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword?.message}
            label="Confirm Password"
            type="password"
            fullWidth
            {...register("confirmPassword", {
              validate: value =>
                value === newPassword || "The passwords do not match",
              required: { value: true, message: "Required" },
            })}
          />

          <Button
            className="mt-4.5"
            loading={isSubmitting}
            type="submit"
            fullWidth
          >
            Create Password
          </Button>
        </form>
      </div>
    </AuthLayout>
  )
}

export default CreatePassword

import { CircularProgress } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    zIndex: theme.zIndex.dialog,
    background: "rgba(0, 0, 0, 0.7)",
    borderRadius: theme.spacing(0.5),
    color: "white !important",
  },
}))

type Props = {
  enabled?: boolean
  message?: JSX.Element | string
  size?: "sm" | "md" | "lg"
}

const DarkOverlayLoading: React.FC<Props> = ({
  children,
  message,
  enabled = true,
  size = "md",
}) => {
  const classes = useStyles()

  if (!enabled) return <>{children}</>

  return (
    <>
      {children}
      <div className={classes.overlay}>
        <CircularProgress size={size} />
        <div className="mt-2">{message}</div>
      </div>
    </>
  )
}

export default DarkOverlayLoading

import { useEffect, useState } from "react"

import { Typography } from "@suraasa/placebo-ui"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"
import { routes } from "utils/routes"

type Props = {
  platform: Platforms
}
const PlatformLogout = ({ platform }: Props) => {
  const [loading, setLoading] = useState(true)

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() => {
    const logout = async () => {
      const origin = searchParams.get("origin")
      const url =
        platform === Platforms.school
          ? `${routes.school.login}/?redirect-url=${origin}`
          : `${routes.partner.login}/?redirect-url=${origin}`

      if (getAuthInfo()) {
        await api.users.logout()
      }
      clearAuthInfo()
      setLoading(false)
      navigate(url, { replace: true })
    }

    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading ? (
    <LoadingOverlay>
      <Typography className="mt-2" variant="strong">
        You will be redirected shortly
      </Typography>
    </LoadingOverlay>
  ) : null
}

export default PlatformLogout

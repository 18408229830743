import React from "react"

import { Avatar, Button, Theme, Typography } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "20px 20px 0px 20px",
      backgroundColor: theme.colors.common.white[500],
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
      marginBottom: theme.spacing(1),
    },
  },
  card: {
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.surface[50],
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${theme.colors.surface[200]}`,
  },
}))

type Props = {
  fullName: string
  picture: string | null | undefined
  onChange?: () => void
  id: string
}

const UserInfo = ({ fullName, picture, onChange, id }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className="mb-1" variant="title3">
        {fullName}
      </Typography>
      <div className={classes.card}>
        <Avatar name={fullName} size="sm" src={picture} />
        <Typography className="ml-1">{id}</Typography>
      </div>
      {onChange && (
        <Button className="mb-2" variant="text" onClick={onChange}>
          Not you? Change
        </Button>
      )}
    </div>
  )
}
export default UserInfo

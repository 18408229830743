import React, { useState } from "react"

import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import metadata from "metadata.json"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"

import api from "api"
import AuthLayout from "components/auth/school/AuthLayout"
import BackButton from "components/shared/BackButton"
import ReactHelmet from "components/shared/ReactHelmet"
import { Product } from "utils/constants"
import { CaptchaType, useGRecaptcha } from "utils/hooks/useGRecaptcha"
import toast from "utils/toast"

const useStyles = createUseStyles({
  root: {
    width: 358,
    maxWidth: "100%",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
})

type FormData = {
  email: string
}

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const classes = useStyles()
  const [emailSent, setEmailSent] = useState(false)

  const { captcha, resetCaptcha, run, RenderCaptcha } = useGRecaptcha("auth")

  const onSubmit = handleSubmit(async data => {
    if (!captcha) {
      toast.error("Please verify the captcha")
      return
    }

    const res = await api.users.forgotPassword({
      data: { ...data, product: Product.school, captcha },
    })
    resetCaptcha()
    if (res.isSuccessful) {
      toast.success("Reset password e-mail has been sent")
      setEmailSent(true)
    } else {
      setEmailSent(false)

      if (res.errors.fieldErrors?.invalidCaptcha) {
        resetCaptcha()
        run(CaptchaType.checkbox)
        toast.error("Verification failed, please try again later")
      }
      if (res.errors.fieldErrors?.user) {
        setError("email", { message: res.errors.fieldErrors?.user })
      }
    }
  })

  const resetForm = () => {
    setEmailSent(false)
    reset({})
  }

  return (
    <>
      <ReactHelmet data={metadata.forgotPassword} />
      <AuthLayout>
        <BackButton className="-ml-1.5 mb-3" />
        <div className={classes.root}>
          {emailSent ? (
            <>
              <Typography className="mb-2" variant="title2">
                Reset password e-mail has been sent to {getValues("email")}
              </Typography>
              <div className="flex flex-col gap-2 mt-4 sm:flex-row">
                <Button fullWidth onClick={onSubmit}>
                  Resend
                </Button>
                <Button variant="text" fullWidth onClick={resetForm}>
                  Change Email
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography className="mb-2" component="h1" variant="title2">
                Forgot your password?
              </Typography>
              <Typography
                className="mb-4"
                color="onSurface.500"
                variant="subtitle2"
              >
                Please enter the email address associated with your Suraasa
                account.
              </Typography>
              <form
                className={clsx("flex flex-col mt-2", classes.root)}
                onSubmit={onSubmit}
              >
                <TextField
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  label="email address"
                  type="email"
                  fullWidth
                  {...register("email", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <RenderCaptcha />

                <Button
                  className="mt-2"
                  loading={isSubmitting}
                  type="submit"
                  fullWidth
                >
                  Send Verification
                </Button>
              </form>
            </>
          )}
        </div>
      </AuthLayout>
    </>
  )
}

export default ForgotPassword

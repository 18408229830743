import { formatISO } from "date-fns"

import axios from "api/axios"
import { ConfigWithURLParams } from "api/types"
import { formatErrorResponse } from "api/utils"

import { urls } from "./urls"

export default {
  markOpened: async ({
    urlParams: { notificationId },
  }: ConfigWithURLParams<"notificationId">) => {
    try {
      await axios.put(urls.notification.detail(notificationId), {
        dateOpened: formatISO(new Date()),
      })
    } catch (error) {
      formatErrorResponse(error)
    }
  },
}

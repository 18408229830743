import React from "react"

import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import Navbar from "components/shared/Navbar"
import { NavbarProps } from "components/shared/Navbar/types"
import { Platforms } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "100vh",
  },
  contentRoot: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "9vh",
    [theme.breakpoints.up("xl")]: {
      gap: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2vh",
    },
    "& > *": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100% !important",
      },
    },
  },
  directionRtl: {
    flexDirection: "row-reverse",
  },
}))

type Props = {
  direction?: "ltr" | "rtl"
  navbarProps?: NavbarProps
  children: [React.ReactChild, React.ReactChild]
}

const AuthLayout = ({ direction = "ltr", children, navbarProps }: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <div className={classes.root}>
      <Navbar {...navbarProps} platform={Platforms.student} />
      <Container>
        <div
          className={clsx(classes.contentRoot, {
            [classes.directionRtl]: direction === "rtl",
          })}
        >
          {children[0]}
          {mdUp && children[1]}
        </div>
      </Container>
    </div>
  )
}

export default AuthLayout

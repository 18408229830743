import React, { useState } from "react"

import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import { ReactComponent as Illustration } from "assets/illustrations/ils1.svg"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { Link, useSearchParams } from "react-router-dom"

import AuthLayout from "components/auth/AuthLayout"
import LinkWithQuery from "components/shared/LinkWithQuery"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { getPlatform } from "utils/auth"
import { Platforms, Product } from "utils/constants"
import { handleErrors } from "utils/helpers"
import { useLogin } from "utils/hooks/useLogin"
import { routes } from "utils/routes"

const useStyles = createUseStyles({
  root: {
    width: 358,
    maxWidth: "100%",
  },
  content: {
    maxWidth: "40%",
  },
})

type FormData = {
  email: string
  password: string
}

export const VALID_PLATFORMS = [
  Platforms.centerHead,
  Platforms.iqa,
  Platforms.professor,
  Platforms.student,
  Platforms.studentJobs,
  Platforms.apiDocs,
]

type Props = {
  onSuccess?: (code: string) => void
}

const Login = ({ onSuccess }: Props) => {
  const classes = useStyles()

  const [searchParams] = useSearchParams()

  const email = searchParams.get("email")
  const platform = getPlatform(searchParams.get("platform"), VALID_PLATFORMS)

  const {
    register,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      email: email || "",
    },
  })

  const [loadingMessage] = useState("")

  const { handleSubmit: onSubmit, loading } = useLogin({
    options: {
      initialLoading: true,
    },
    product: Product.learning,
    platform,
    onSuccess,
  })

  const submitForm = handleSubmit(async data => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const errors = await onSubmit(data)

    if (errors) {
      handleErrors(setError, errors)
    }
  })

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  return (
    <AuthLayout direction="rtl">
      <div>
        <form
          className={clsx("flex flex-col gap-2 mt-3 mx-auto", classes.root)}
          onSubmit={submitForm}
        >
          <TextField
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            label="email address"
            type="email"
            fullWidth
            {...register("email", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            autoComplete="password"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            label="password"
            type="password"
            fullWidth
            {...register("password", {
              required: { value: true, message: "Required" },
            })}
          />
          <Button
            className="mb-3"
            component={Link}
            to={routes.forgotPassword}
            variant="link"
          >
            Forgot Password
          </Button>
          <Button
            className="mt-3"
            loading={isSubmitting}
            type="submit"
            fullWidth
          >
            Sign in
          </Button>
        </form>
        <div className="mt-2 flex flex-col sm:flex-row items-center justify-center">
          <Typography textAlign="center" variant="body">
            Don’t have a Suraasa account?
          </Typography>
          <Button component={LinkWithQuery} to={routes.signUp} variant="link">
            Sign Up
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <Typography className="mb-2" variant="title1">
          Sign in to your Suraasa Account.
        </Typography>
        <Typography className="mb-5" color="onSurface.500" variant="largeBody">
          One Suraasa Account for everything on Suraasa.
        </Typography>
        <Illustration />
      </div>
    </AuthLayout>
  )
}

export default Login

import { captureException } from "@sentry/react"
import { Notification } from "@suraasa/notifications"
import { FieldValues, UseFormSetError } from "react-hook-form"

import { AuthData } from "api/resources/users/types"
// import api from "api"
import { ErrorResponse } from "api/types"
import { NotificationAction } from "utils/notifications"
import toast from "utils/toast"

import { GA } from "./googleAnalytics"

export function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

// placebo-issue exported in @suraasa/placebo-ui but not merged as of now
export function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex: string = hex.replace("#", "")

  const r = parseInt(tempHex.substring(0, 2), 16)
  const g = parseInt(tempHex.substring(2, 4), 16)
  const b = parseInt(tempHex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

export const handleErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  { fieldErrors, message }: ErrorResponse["errors"]
) => {
  if (message) {
    toast.error(message)
  }
  if (fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      if (v) setter(k as any, { message: v })
    }
  }
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobData
  link.download = name
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobData)
  }, 100)
}

export function formatAssignmentTitle(title: string, shortTitle: string) {
  if (!shortTitle || shortTitle === title) return title

  return `${shortTitle}: ${title}`
}

export const getPlatformURL = (
  platform: "learn" | "suraasa" | "jobs",
  url: string
) => {
  switch (platform) {
    case "learn":
      return `${process.env.REACT_APP_LEARN_PLATFORM_URL}${url}`
    case "suraasa":
      return `${process.env.REACT_APP_SURAASA_PLATFORM_URL}${url}`
    case "jobs":
      return `${process.env.REACT_APP_JOBS_PLATFORM_URL}${url}`
    default:
      return url
  }
}

export const notificationHelper = (item: Notification<NotificationAction>) => {
  const routes = {
    home: getPlatformURL("jobs", "/home"),
    jobTimeline: getPlatformURL("jobs", "/job/:jobId/:jobApplicant/timeline"),
    viewOffer: getPlatformURL("jobs", "/job/:jobOfferId/offer"),

    discussions: getPlatformURL("learn", "/dashboard/discussions"),
    discussion: getPlatformURL("learn", "/dashboard/discussions/:discussionId"),
    startAssessment: getPlatformURL(
      "learn",
      "/dashboard/assessments/attempt/start/:attemptId"
    ),
    classDetail: getPlatformURL("learn", "/dashboard/attendance"),
    myLibrary: getPlatformURL("learn", "/dashboard/library"),
    homePage: getPlatformURL("suraasa", "/"),
    courseDetail: getPlatformURL(
      "learn",
      "/dashboard/library/course/:courseId"
    ),
    orderDetail: getPlatformURL("learn", "/dashboard/orders/:orderId"),
    certificateDetail: getPlatformURL(
      "learn",
      "/certificate/verify/:certificateNumber"
    ),
    viewSubmission: getPlatformURL(
      "learn",
      "/dashboard/assignments/submission/view/:assignmentId/:submissionId"
    ),
    schoolProfile: getPlatformURL("jobs", "/school/:slug"),
  }
  const action = item?.action
  if (!action || !("name" in action)) return

  action.name = action.name.trim() as NotificationAction["name"]
  switch (action.name) {
    case "JOB_OFFER_RECEIVED":
      window.open(
        routes.viewOffer.replace(":jobOfferId", String(action.data.jobOfferId))
      )
      break
    case "INTERVIEW_SCHEDULED":
    case "LIVE_DEMO_SCHEDULED":
    case "SCHOOL_ASSESSMENT_SCHEDULED":
      window.open(
        `${routes.jobTimeline
          .replace(":jobId", String(action.data.jobId))
          .replace(
            ":jobApplicant",
            action.data.jobApplicant.toString()
          )}?jobApplicantStepId=${action.data.jobApplicantStepId}`
      )
      break
    case "INVITE_RECEIVED":
      window.open(
        `${routes.home}?tab=Invitations&inviteId=${action.data.jobApplicantId}`
      )
      break
    case "JOB_PUBLISHED":
      window.open(
        `${routes.schoolProfile.replace(
          ":slug",
          action.data.schoolSlug
        )}?jobSlug=${action.data.jobSlug}`
      )
      break
    case "REDIRECT_TO_DISCUSSION_DETAIL":
    case "REDIRECT_TO_DISCUSSIONS_DETAIL":
      if (action.data.userDiscussionId) {
        window.open(
          routes.discussion.replace(
            ":discussionId",
            action.data.userDiscussionId
          )
        )
      } else window.open(routes.discussions)
      break
    case "REDIRECT_TO_TRENDING_DISCUSSIONS":
      window.open(routes.discussions)
      break
    case "REDIRECT_TO_ASSESSMENT_DETAIL":
      if (action.data.assessmentUuid) {
        window.open(
          routes.startAssessment.replace(
            ":attemptId",
            action.data.assessmentUuid
          )
        )
      }
      break
    case "REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL":
      if (action.data.assignmentUuid && action.data.submissionUuid) {
        window.open(
          routes.viewSubmission
            .replace(":assignmentId", action.data.assignmentUuid)
            .replace(":submissionId", action.data.submissionUuid)
        )
      }
      break
    case "REDIRECT_TO_CLASS_DETAIL":
      window.open(routes.classDetail)
      break
    case "REDIRECT_TO_ORDER_DETAIL":
    case "TRANSACTION_FAILED":
    case "TRANSACTION_SUCCESSFUL":
    case "DUE_DATE_APPROACHING":
      window.open(routes.orderDetail.replace(":orderId", action.data.orderId))
      break
    // case "REDIRECT_TO_CENTRE_SCHEDULE_DETAIL":
    case "REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL":
    case "REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL":
      window.open(
        routes.certificateDetail.replace(
          ":certificateNumber",
          action.data.certificateNumber
        )
      )
      break
    case "REDIRECT_TO_COURSE_CERTIFICATE_DETAIL":
      window.open(
        routes.courseDetail.replace(":courseId", action.data.courseId)
      )
      break
    case "REDIRECT_TO_MY_LIBRARY":
    case "ITEMS_ALLOCATED":
      window.open(routes.myLibrary)
      break
    case "REDIRECT_TO_HOMEPAGE":
      window.open(routes.homePage)
      break
    default:
      captureException(new Error("Action type not handled"), scope => {
        scope.setExtras({
          backendError: JSON.stringify(item),
        })
        return scope
      })
      break
  }
  return undefined
}

export function checkEnvVars(vars: string[]) {
  for (const v of vars) {
    if (!process.env[v]) {
      throw new Error(`Please add ${v} to your environment variables`)
    }
  }
}

export const trackUser = (
  authInfo: {
    user: Pick<AuthData["user"], "firstName" | "lastName" | "email" | "uuid">
  },
  options?: { timeout?: number }
) => {
  try {
    // We have stopped sending PII from frontend
    setTimeout(() => {
      GA.setUserId(authInfo.user.uuid)
    }, options?.timeout || 0)
  } catch (e) {
    console.error(e)
  }
}

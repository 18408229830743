/* eslint-disable no-lonely-if */
import { useEffect, useState } from "react"

import {
  Button,
  InputLabel,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles, useTheme } from "react-jss"
import OtpInput from "react-otp-input"
import { useLocation, useNavigate } from "react-router"
import { Link, useSearchParams } from "react-router-dom"

import { ArrowRight, InfoEmpty } from "iconoir-react"

import api from "api"
import { HybridLoginType } from "api/resources/users/types"
import UserInfo from "components/auth/UserInfo"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import { getAuthRoute, getPlatform, getProbablePlatform } from "utils/auth"
import { Platforms, Product } from "utils/constants"
import { handleErrors } from "utils/helpers"
import { CaptchaType, useGRecaptcha } from "utils/hooks/useGRecaptcha"
import { useHybridLogin } from "utils/hooks/useHybridLogin"
import { routes } from "utils/routes"
import toast from "utils/toast"

type FormData = {
  identifier: string
  otp?: string
  password?: string
}

const useStyles = createUseStyles((theme: Theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexGrow: 1,
  },
  title: {
    letterSpacing: "-0.04em",
  },
  formPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },
  },
  root: {
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      margin: "auto",
      padding: 30,
      width: 500,
      background: "white",
      borderRadius: "12px",
      boxShadow: "1px 1px 5px rgb(0 0 0 / 10%)",
    },
  },
  signUpBtn: {
    ...theme.typography.strongSmallBody,
    color: "black",
    textDecoration: "underline",
    textUnderlineOffset: "5px",
    marginInlineStart: "2px",
  },
  link: {
    ...theme.typography.smallBody,
    color: theme.colors.primary[500],
  },
  containerStyle: {
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  inputStyle: {
    borderRadius: 4,
    width: `41px !important`,
    height: 50,
    border: `2px solid ${theme.colors.onSurface[500]}`,
    ...theme.typography.title2,
  },
  focusStyle: {
    outline: `2px solid ${theme.colors.interactive[400]}`,
    outlineOffset: "1px",
  },
  errorStyle: {
    border: `2px solid ${theme.colors.critical[500]}`,
  },
  banner: {
    background: theme.colors.highlight[500],
    padding: theme.spacing(1.5, 2),
    textAlign: "left",
  },
}))

export const VALID_PLATFORMS = Object.values(Platforms)

type Props = {
  product: Product
  onAuthCodeGen?: (code: string) => void
}

const HybridLogin = ({ product, onAuthCodeGen }: Props) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const theme = useTheme<Theme>()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const [banner, setBanner] = useState("")

  const bannerText = (location.state as { bannerText: string })?.bannerText

  const {
    register,
    setError,
    setValue,
    getValues,
    control,
    setFocus,
    handleSubmit,
    watch,
    unregister,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      identifier:
        searchParams.get("email") || searchParams.get("phoneNumber") || "",
    },
    shouldUnregister: true,
  })

  const platform = getProbablePlatform(
    product,
    getPlatform(searchParams.get("platform"), VALID_PLATFORMS)
  )

  const identifier = watch("identifier")

  const { onLoginSuccess, loading } = useHybridLogin({
    product,
    options: {
      initialLoading: true,
      onAuthCodeGen,
      processRedirect: !onAuthCodeGen,
      iframe: searchParams.get("iframe") === "true",
    },
    platform,
  })

  const [step, setStep] = useState<"identifier" | "password" | "otp">(
    "identifier"
  )

  const [otpData, setOtpData] = useState<{
    token: string
    resendAt: string
  } | null>(null)

  const [user, setUser] = useState<HybridLoginType | null>(null)
  const hasPassword = user?.hasPassword

  const { captcha, resetCaptcha, run, RenderCaptcha } = useGRecaptcha("auth")
  const getUserDetails = async (id: string) => {
    const res = await api.users.getUserDetails({
      data: {
        userId: id.trim(),
        product,
      },
    })
    if (res.isSuccessful) {
      setUser(res.data)
      if (res.data.hasPassword) {
        setStep("password")
      } else {
        setStep("otp")
      }
    } else {
      if (res.errors.message) {
        toast.error(res.errors.message)
      }
      if (res.errors.fieldErrors?.userId) {
        setError("identifier", { message: res.errors.fieldErrors.userId })
      }
      if (res.errors.fieldErrors?.countryCode) {
        setError("identifier", { message: res.errors.fieldErrors.countryCode })
      }
    }
  }

  const requestOTP = async () => {
    if (!user) {
      throw new Error("No user, returning early")
    }

    const data = otpData?.token
      ? {
          token: otpData.token,
        }
      : {
          medium: user.phoneNumber ? "sms" : "email",
          // Only applicable in case of sms
          channel: user.phoneNumber ? "both" : undefined,
          purpose: "login",
          user: user.uuid,
        }

    const res = await api.users.sendUserOTP({
      data: {
        ...data,
        captcha,
      },
    })
    if (res.isSuccessful) {
      setOtpData(res.data)
    } else {
      if (res.errors.fieldErrors?.invalidCaptcha) {
        resetCaptcha()
        run(CaptchaType.checkbox)
        toast.error("Verification failed, please try again later")
      }
      if (res.errors.message) {
        toast.error(res.errors.message)
      }

      if (res.errors.fieldErrors?.resendAt) {
        setOtpData(prev => ({
          token: res.errors.fieldErrors?.token || prev?.token || "",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          resendAt: res.errors.fieldErrors!.resendAt,
        }))
      }
    }
  }

  const signin = async (data: FormData) => {
    if (!user) return

    if (step === "password" && data.password) {
      const res = await api.users.login({
        data: {
          uuid: user.uuid,
          password: data.password,
          product,
        },
      })
      if (res.isSuccessful) {
        onLoginSuccess(res.data)
      } else {
        handleErrors(setError, res.errors)
      }

      return
    }

    if (step === "otp" && data.otp) {
      const res = await api.users.verifyUserOTP({
        data: {
          universalToken: true,
          token: otpData?.token,
          otp: data.otp,
        },
      })
      if (res.isSuccessful) {
        if ("universal" in res.data) {
          onLoginSuccess({
            ...res.data.universal,
            user: res.data.user,
          })
        }
      } else {
        if (res.errors.fieldErrors?.otp) {
          handleErrors(setError, res.errors)
        }
      }
    }
  }

  const onSubmit = handleSubmit(formData => {
    if (step === "identifier") {
      setValue("password", "")
      setValue("otp", "")
      return getUserDetails(formData.identifier)
    }

    return signin(formData)
  })

  useEffect(() => {
    /**
     * We are consuming location.state.bannerText and then deleting it from state
     * because it doesn't get deleted on refresh or route change
     */
    setBanner(bannerText)
    navigate(location.pathname + location.search, {
      replace: true,
      state: undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (step === "identifier") {
      unregister("password")
      unregister("otp")
    }
    // Clear field value on step switch
    if (step === "otp") {
      unregister("password")
      setValue("password", "")
    }
    if (step === "password") {
      unregister("otp")
      setValue("otp", "")
      setFocus("password")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (captcha) {
      requestOTP()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha])

  useEffect(() => {
    if (step === "otp") {
      run(CaptchaType.invisible)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      {banner && (
        <div className={clsx("flex items-center", classes.banner)}>
          {isMdUp && (
            <InfoEmpty className="shrink-0" color="white" width={20} />
          )}
          <Typography
            color="common.white.200"
            style={{ marginInlineStart: "8px" }}
          >
            {banner}
          </Typography>
        </div>
      )}
      <Navbar gutterBottom={false} platform={platform} />

      <div className={classes.content}>
        <div
          className={clsx(classes.root, {
            [classes.formPadding]: step === "identifier",
          })}
        >
          {step === "identifier" && (
            <div>
              <Typography
                className={classes.title}
                component="h1"
                variant="title1"
              >
                Sign in to Suraasa
              </Typography>
              <Typography
                className="mb-4"
                color="onSurface.500"
                variant="subtitle2"
              >
                One account for everything on Suraasa.
              </Typography>
              <div className="flex justify-between">
                <InputLabel label="Email or phone number" />
                <Button
                  className={classes.link}
                  component={Link}
                  to={getAuthRoute({
                    search: {
                      identifier: identifier ?? "",
                    },
                    key: "forgotPassword",
                    platform,
                    product,
                  })}
                  variant="link"
                >
                  Forgot Password?
                </Button>
              </div>
              <TextField
                error={Boolean(errors.identifier)}
                helperText={errors.identifier?.message}
                autoFocus
                fullWidth
                {...register("identifier", {
                  required: { value: true, message: "Required" },
                })}
              />

              <Button
                className="mt-3"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                type="submit"
                fullWidth
              >
                Next
              </Button>

              <div className="flex flex-wrap items-center justify-center mt-2">
                <Typography
                  className="mr-0.5"
                  color="onSurface.500"
                  variant="smallBody"
                >
                  Don’t have an account?
                </Typography>

                <Button
                  className={classes.signUpBtn}
                  component={Link}
                  to={routes.signUp}
                  variant="link"
                >
                  Sign up for free
                </Button>
              </div>
            </div>
          )}
          {step === "password" && (
            <div>
              {user && (
                <UserInfo
                  fullName={user.fullName}
                  id={getValues("identifier")}
                  picture={user?.profile?.picture}
                  onChange={() => {
                    setStep("identifier")
                  }}
                />
              )}
              <div className={classes.formPadding}>
                <div className="flex items-center justify-between">
                  <InputLabel label="Password" />
                  <Button
                    className={classes.link}
                    component={Link}
                    to={getAuthRoute({
                      search: {
                        identifier: identifier ?? "",
                      },
                      key: "forgotPassword",
                      platform,
                      product,
                    })}
                    variant="link"
                  >
                    Forgot Password?
                  </Button>
                </div>

                <TextField
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  type="password"
                  autoFocus
                  fullWidth
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                <Button
                  className="mt-3"
                  loading={isSubmitting}
                  type="submit"
                  fullWidth
                >
                  Sign in
                </Button>

                <div className="flex items-center justify-center mt-2">
                  <Button
                    disabled={isSubmitting}
                    variant="text"
                    onClick={() => {
                      setStep("otp")
                    }}
                  >
                    Sign in with OTP
                  </Button>
                </div>
              </div>
            </div>
          )}

          {step === "otp" && (
            <div>
              {user && (
                <UserInfo
                  fullName={user.fullName}
                  id={getValues("identifier")}
                  picture={user?.profile?.picture}
                  onChange={() => {
                    setStep("identifier")
                  }}
                />
              )}

              <div className={classes.formPadding}>
                {captcha && (
                  <div className="mb-2">
                    {user && (
                      <Typography className="mb-3">
                        A 6-digit verification code was just sent to your{" "}
                        {user.email ? "email" : "phone number"}. Please check
                        your {user.email ? "inbox" : "messages"}.
                      </Typography>
                    )}
                    <div className="flex items-center justify-between">
                      <InputLabel label="Enter OTP" />
                      {/* <ResendOTPButton
                        resendAt={otpData?.resendAt}
                        text="Resend OTP"
                        timerText="Resend in"
                        onClick={() => {
                          requestOTP()
                        }}
                      /> */}
                    </div>
                    <Controller
                      control={control}
                      name="otp"
                      render={({ field: { onChange, ...restFields } }) => (
                        <OtpInput
                          numInputs={6}
                          onChange={(x: any) => {
                            onChange(x)
                            if (typeof x === "string" && x.length === 6) {
                              onSubmit()
                            }
                          }}
                          {...restFields}
                          containerStyle={classes.containerStyle}
                          errorStyle={classes.errorStyle}
                          focusStyle={classes.focusStyle}
                          hasErrored={Boolean(errors.otp)}
                          inputStyle={classes.inputStyle}
                          isInputNum
                          shouldAutoFocus
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Required" },
                      }}
                    />
                  </div>
                )}
                {Boolean(errors.otp) && (
                  <Typography
                    className="mt-1"
                    color="critical.500"
                    variant="smallBody"
                  >
                    {errors.otp?.message}
                  </Typography>
                )}
                <RenderCaptcha />
                <Button
                  className="mt-3"
                  loading={isSubmitting}
                  type="submit"
                  fullWidth
                >
                  Sign in
                </Button>
                {hasPassword && (
                  <div className="flex items-center justify-center mt-2">
                    <Button
                      disabled={isSubmitting}
                      variant="text"
                      onClick={() => {
                        setStep("password")
                      }}
                    >
                      Sign in with password
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default HybridLogin

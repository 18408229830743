import { useEffect, useState } from "react"

import { Button, TextField, Theme, Typography } from "@suraasa/placebo-ui"
import { useForm } from "react-hook-form"
import { createUseStyles, useTheme } from "react-jss"
import { Link, useNavigate, useSearchParams } from "react-router-dom"

import { ArrowRight } from "iconoir-react"

import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import {
  getAuthInfo,
  getPlatform,
  isValidPlatform,
  saveAuthInfo,
} from "utils/auth"
import { Platforms } from "utils/constants"
import { GA, GA_EVENTS } from "utils/googleAnalytics"
import { getPlatformURL, handleErrors, trackUser } from "utils/helpers"
import { CaptchaType, useGRecaptcha } from "utils/hooks/useGRecaptcha"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { trackingService } from "utils/tracking"

import { VALID_PLATFORMS } from "./Login"

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  content: {
    display: "flex",
    flexGrow: 1,
  },

  form: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },

    [theme.breakpoints.up("sm")]: {
      margin: "auto",
      padding: 30,
      width: 500,
      background: "white",
      borderRadius: "12px",
      boxShadow: "1px 1px 5px rgb(0 0 0 / 10%)",
    },
  },
  signUpBtn: {
    ...theme.typography.strongSmallBody,
    color: "black",
    textDecoration: "underline",
    textUnderlineOffset: "5px",
    marginInlineStart: "2px",
  },
}))

const formatName = (name: string) => {
  if (name.length > 20) {
    return `${name.substring(0, 20)}...`
  }
  return name
}

type FormData = {
  firstName: string
  lastName: string
  email: string
  password1: string
  password2: string
}

const SignUp = () => {
  const classes = useStyles()
  const [step, setStep] = useState<"name" | "password" | "email">("name")
  const theme = useTheme<Theme>()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const platform = getPlatform(searchParams.get("platform"))

  const [loading, toggleLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState("")

  const {
    register,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const handleRedirect = (code?: string) => {
    const redirectUrl = searchParams.get("redirect-url")
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      if (code) url.searchParams.append("code", code)
      window.location.href = url.href
    } else {
      console.warn("> redirect-url not found. Redirecting to profile")
      navigate(routes.profile)
    }
  }

  const generateAuthCode = async (platformKey: Platforms) => {
    toggleLoading(true)
    setLoadingMessage("You will be redirected shortly")
    const res = await api.users.generateAuthCode({
      data: { platform: platformKey },
    })
    if (res.isSuccessful) {
      const { code } = res.data
      handleRedirect(code)
    } else {
      toggleLoading(false)
      setLoadingMessage("")
      if (res.errors.message) console.log(res.errors.message)
    }
  }

  const { captcha, resetCaptcha, run, RenderCaptcha } = useGRecaptcha("auth")

  useEffect(() => {
    if (step === "password") run(CaptchaType.invisible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const onSubmit = handleSubmit(async formData => {
    clearErrors()
    if (step === "name") {
      setStep("email")
      return
    }
    if (step === "email") {
      setStep("password")
      return
    }

    if (!captcha) {
      resetCaptcha()
      run(CaptchaType.checkbox)
      toast.error("Please verify the captcha")
      return
    }

    const res = await api.users.signUp({
      data: {
        ...formData,
        captcha,
        email: formData.email.trim(),
        firstName: formData.firstName.trim(),
        lastName: formData.lastName?.trim() || null,
        token_type: 1,
        platform: "Student",
        /**
         * This is because password1 gets converted to password_1 by lodash. We don't want that.
         * So we skip transformation.
         */
        __noTransform: true,
      },
    })
    if (res.isSuccessful) {
      saveAuthInfo({ ...res.data, platform: platform ?? Platforms.student })
      trackUser(res.data)
      trackingService.initUser(res.data)
      trackingService.trackEvent(
        "USER_SIGNUP",
        {
          uuid: res.data.user.uuid,
          email: formData.email.trim(),
          firstName: formData.firstName.trim(),
          lastName: formData.lastName?.trim() || null,
          platform: platform ?? Platforms.student,
        },
        ["NetCore"]
      )

      GA.trackEvent(GA_EVENTS.signup, {})

      if (platform) {
        await generateAuthCode(platform)
      } else {
        navigate(routes.profile)
      }
    } else {
      handleErrors(setError, res.errors)
      if (res.errors.fieldErrors?.invalidCaptcha) {
        resetCaptcha()
        run(CaptchaType.checkbox)
        toast.error("Verification failed, please try again later")
      }
      if (res.errors.fieldErrors?.email) {
        setStep("email")
      }
      if (
        res.errors.fieldErrors?.password1 ||
        res.errors.fieldErrors?.password2
      ) {
        setStep("password")
      }
      if (
        res.errors.fieldErrors?.firstName ||
        res.errors.fieldErrors?.lastName
      ) {
        setStep("name")
      }
    }
  })

  useEffect(() => {
    const authInfo = getAuthInfo()

    if (authInfo && isValidPlatform(authInfo?.platform, VALID_PLATFORMS)) {
      handleRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  const password1 = watch("password1")

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  return (
    <div className={classes.root}>
      <Navbar gutterBottom={false} platform={platform ?? undefined} />

      <div className={classes.content}>
        <form className={classes.form} onSubmit={onSubmit}>
          {step === "name" && (
            <div>
              <Typography variant="title1">Sign up for Suraasa</Typography>
              <Typography
                className="mt-0.5 mb-4"
                color="onSurface.500"
                variant="subtitle2"
              >
                One Suraasa Account for everything on Suraasa.
              </Typography>
              <TextField
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
                label="First Name"
                type="text"
                autoFocus
                fullWidth
                {...register("firstName", {
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 64,
                    message: "Length cannot be greater than 64",
                  },
                })}
              />
              <TextField
                className="mt-1.5"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
                label="Last Name"
                type="text"
                fullWidth
                {...register("lastName", {
                  maxLength: {
                    value: 64,
                    message: "Length cannot be greater than 64",
                  },
                })}
              />
            </div>
          )}
          {step === "email" && (
            <div>
              <Typography className="mb-3" variant="title1">
                Alright, {formatName(getValues("firstName"))}. Now onto your
                email
              </Typography>
              <TextField
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                label="Email"
                type="email"
                autoFocus
                fullWidth
                {...register("email", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
          )}
          {step === "password" && (
            <div>
              <Typography className="mb-3" variant="title1">
                Great! Let's set up a password.
              </Typography>
              <TextField
                autoComplete="password1"
                error={Boolean(errors.password1)}
                helperText={errors.password1?.message}
                label="password"
                type="password"
                autoFocus
                fullWidth
                {...register("password1", {
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  onChange: () => clearErrors("password1"),
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                autoComplete="password2"
                className="mt-2"
                error={Boolean(errors.password2)}
                helperText={errors.password2?.message}
                label="Confirm Password"
                type="password"
                fullWidth
                {...register("password2", {
                  validate: value =>
                    value === password1 || "The passwords do not match",
                  required: { value: true, message: "Required" },
                })}
              />

              <RenderCaptcha className="mt-2" />

              <Button
                className="mt-2"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                type="submit"
                fullWidth
              >
                Create Account
              </Button>
              <Typography
                className="mt-2"
                color="onSurface.500"
                style={{ textAlign: "center" }}
                variant="smallBody"
              >
                Creating an account means you agree to our{" "}
                <a
                  href={getPlatformURL("suraasa", "/terms-of-use")}
                  rel="noreferrer"
                  style={{ color: theme.colors.primary[500] }}
                  target="_blank"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href={getPlatformURL("suraasa", "/privacy-policy")}
                  rel="noreferrer"
                  style={{ color: theme.colors.primary[500] }}
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Typography>
            </div>
          )}

          {step !== "password" && (
            <div className="flex flex-col">
              <Button
                className="mt-3"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                type="submit"
                fullWidth
              >
                Next
              </Button>

              <div className="flex flex-wrap items-center justify-center mt-2">
                <Typography color="onSurface.500" variant="smallBody">
                  Already have an account?
                </Typography>
                <Button
                  className={classes.signUpBtn}
                  component={Link}
                  to={routes.login}
                  variant="link"
                >
                  Sign in instead
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default SignUp

import { useCallback, useState } from "react"

import toast from "utils/toast"

export interface CustomWindow extends Window {
  grecaptcha?: {
    enterprise: {
      ready: (callback: () => void) => void
      reset: () => void
      render: (
        elementId: string,
        options: {
          sitekey: string
          callback: (token: string) => void
          "error-callback": () => void
          "expired-callback": () => void
          action: string
        }
      ) => void
    }
  }
}

declare let window: CustomWindow

type Captcha = {
  value: string
  type: CaptchaType
}

export enum CaptchaType {
  checkbox = "checkbox",
  invisible = "invisible",
}

const CaptchaID = {
  checkbox: "google-recaptcha-checkbox",
  invisible: "google-recaptcha-invisible",
} as const

type RenderCaptchaProps = {
  type: CaptchaType
  className?: string
}

const RenderCaptcha = ({ type, className }: RenderCaptchaProps) =>
  type === CaptchaType.checkbox ? (
    <div className={className} id={CaptchaID.checkbox} />
  ) : (
    <div className={className} data-size="invisible" id={CaptchaID.invisible} />
  )

export const useGRecaptcha = (action: string) => {
  const [captcha, setCaptcha] = useState<Captcha | null>(null)

  const [captchaType, setCaptchaType] = useState<Captcha["type"]>()

  const run = useCallback(
    (captchaTypeParam: CaptchaType) => {
      const isCheckbox = captchaTypeParam === CaptchaType.checkbox

      setCaptchaType(captchaTypeParam)
      setCaptcha(null)

      const verifyCallback = (verifiedToken: string) => {
        setCaptcha({
          value: verifiedToken,
          type: captchaTypeParam,
        })
      }
      const expiredCallback = () => {
        toast.warning("Please verify the captcha again")
        setCaptcha(null)
      }
      const errorCallback = () => {
        toast.error("There was an error processing captcha. Please try again")
        setCaptcha(null)
      }

      const siteKey = isCheckbox
        ? process.env.REACT_APP_CHECKBOX_RECAPTCHA_KEY
        : process.env.REACT_APP_INVISIBLE_RECAPTCHA_KEY

      const elementId = isCheckbox ? CaptchaID.checkbox : CaptchaID.invisible
      if (window.grecaptcha && siteKey) {
        window.grecaptcha.enterprise.ready(() => {
          try {
            window.grecaptcha?.enterprise.render(elementId, {
              sitekey: siteKey,
              callback: verifyCallback,
              action,
              "expired-callback": expiredCallback,
              "error-callback": errorCallback,
            })
            // @ts-expect-error exists
            if (!isCheckbox) window.grecaptcha?.enterprise.execute()
          } catch (e) {
            console.error(e)
          }
        })
      }
    },
    [action]
  )

  const resetCaptcha = () => {
    try {
      setCaptcha(null)
      window.grecaptcha?.enterprise.reset()
    } catch (e) {
      console.error(e)
    }
  }

  const Component = useCallback(
    ({ className }: Pick<RenderCaptchaProps, "className">) =>
      captchaType ? (
        <RenderCaptcha
          className={className}
          key={captchaType}
          type={captchaType}
        />
      ) : null,
    [captchaType]
  )

  return {
    captcha,
    resetCaptcha,
    run,
    RenderCaptcha: Component,
  }
}

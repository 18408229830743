import { Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import Navbar from "components/shared/Navbar"
import { Platforms } from "utils/constants"

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexGrow: 1,
  },
  inner: {
    width: "100%",

    padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      margin: "auto",
      padding: 30,
      width: 500,
      background: "white",
      borderRadius: "12px",
      boxShadow: "1px 1px 5px rgb(0 0 0 / 10%)",
    },
  },
  banner: {
    background: theme.colors.highlight[500],
    padding: theme.spacing(1.5, 2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
}))

type Props = {
  platform: Platforms
  children: React.ReactNode
  banner?: string
  className?: string
}

const AuthLayout = ({ platform, children, banner, className }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {banner && (
        <Typography
          className={classes.banner}
          color="common.white.200"
          variant="largeBody"
        >
          {banner}
        </Typography>
      )}
      <Navbar gutterBottom={false} platform={platform} />

      <div className={classes.content}>
        <div className={clsx(classes.inner, className)}>{children}</div>
      </div>
    </div>
  )
}

export default AuthLayout

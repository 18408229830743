import { useEffect } from "react"

import { Typography } from "@suraasa/placebo-ui"

import LoadingOverlay from "components/shared/LoadingOverlay"
import useLogout from "utils/hooks/useLogout"

const Logout = () => {
  const { isLoading, logout } = useLogout({ shouldNavigate: true })

  useEffect(() => {
    logout()
  }, [logout])

  return isLoading ? (
    <LoadingOverlay>
      <Typography className="mt-2" variant="strong">
        You will be redirected shortly
      </Typography>
    </LoadingOverlay>
  ) : null
}

export default Logout

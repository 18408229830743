import React, { useEffect, useState } from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"
import { Link, useParams, useSearchParams } from "react-router-dom"

import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import ResetPassword from "components/shared/ResetPassword"
import { getAuthRoute, getProbablePlatform } from "utils/auth"
import {
  CENTRE_PLATFORM_URL,
  LEARN_PLATFORM_URL,
  PARTNER_PLATFORM_URL,
  Product,
  SCHOOL_PLATFORM_URL,
} from "utils/constants"

import CreatePassword from "./CreatePassword"

const useStyles = createUseStyles(theme => ({
  root: {
    width: 358,
    maxWidth: "100%",
  },
  content: {
    maxWidth: "50%",
  },
  errorContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}))

type Props = {
  createNew?: boolean
}

const parseProduct = (product: string | undefined | null) => {
  if (!product) {
    return Product.learning
  }

  const parsed = parseInt(product)
  if (!parsed) return Product.learning

  return parsed
}

const ResetPasswordHandler = (props?: Props) => {
  const classes = useStyles()
  const { uuid, token, timestamp } =
    useParams<{ uuid: string; token: string; timestamp: string }>()

  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  const [searchParams] = useSearchParams()

  const [product, setProduct] = useState<Product>(
    parseProduct(searchParams.get("product"))
  )

  useEffect(() => {
    const handleReset = async () => {
      if (!uuid || !token || !timestamp) {
        setErrorMessage("The link you followed is incorrect")
      } else {
        const res = await api.users.verifyPasswordSignature({
          urlParams: {
            uuid,
            token,
            timestamp,
          },
        })
        if (res.isSuccessful) {
          setLoading(false)
          setProduct(res.data.product)
        } else {
          setErrorMessage(
            res.errors.message ?? "Your reset password link has expired"
          )
        }
      }
      setLoading(false)
    }

    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, token, timestamp])

  const platform = getProbablePlatform(product)

  if (errorMessage) {
    return (
      <div className={classes.errorContainer}>
        <Typography variant="largeBody">{errorMessage}</Typography>
        <Button
          component={Link}
          to={getAuthRoute({
            key: "forgotPassword",
            product,
            platform,
          })}
          variant="link"
        >
          Reset again
        </Button>
      </div>
    )
  }

  const getProductLayout = () => {
    const Component = props?.createNew ? CreatePassword : ResetPassword

    switch (product) {
      case Product.learning:
        return (
          <Component platform={platform} redirectUrl={LEARN_PLATFORM_URL} />
        )
      case Product.school:
        return (
          <Component platform={platform} redirectUrl={SCHOOL_PLATFORM_URL} />
        )
      case Product.partner:
        return (
          <Component platform={platform} redirectUrl={PARTNER_PLATFORM_URL} />
        )
      case Product.center:
        return (
          <Component platform={platform} redirectUrl={CENTRE_PLATFORM_URL} />
        )
      case Product.internal:
        return (
          <Component platform={platform} redirectUrl={LEARN_PLATFORM_URL} />
        )

      default:
        throw new Error("Shouldn't reach here")
    }
  }

  if (loading) {
    return <LoadingOverlay />
  }

  return <>{getProductLayout()}</>
}

export default ResetPasswordHandler

export const routes = {
  dashboard: "/dashboard",

  profile: "/dashboard/profile/",
  editProfile: "/dashboard/profile/edit",
  publicProfile: "/profile/:username",

  skillProfile: "/dashboard/skill-profile",
  skillEvidence: "/skill-profile/evidence/:submissionId",
  publicSkillProfile: "/skill-profile/:username",

  notifications: "/dashboard/notifications",

  verificationProcess: "/verification-process",
  viewSkillEvidence: (submissionId: string) =>
    `/skill-profile/evidence/${submissionId}`,
  verifyCertificateWithNumber: "/certificate/verify/:certificateNumber",
  verifyCertificate: "/certificate/verify/:username/:slug",

  olympiadRegistrationFormSSO: "/olympiad-registration-form-sso",
  iframeSSO: "/iframe-sso",
  popupSSO: "/popup-sso",
  zoomAuth: "/zoom-auth",
  login: "/",
  svp: {
    login: "/svp/login",
    forgotPassword: "/svp/forgot-password",
  },
  internal: {
    login: "/internal/login",
    forgotPassword: "/internal/forgot-password",
  },
  centre: {
    login: "/centre/login",
    forgotPassword: "/centre/forgot-password",
  },
  logout: "/logout",
  signUp: "/signup",
  help: "/help",
  resetPassword: "/reset-password/:uuid/:token/:timestamp",
  forgotPassword: "/forgot-password",
  createBetaPassword: "/beta/create-password/:id/:token",
  createPassword: "/create-password/:uuid/:token/:timestamp",
  ryanTeacherSignup: "/ryan/signup",
  school: {
    login: "/school/login",
    logout: "/school/logout",
    signUp: "/school/signup",
    resetPassword: "/school/reset-password/:uuid/:token/:timestamp",
    forgotPassword: "/school/forgot-password",
  },
  partner: {
    login: "/partner/login",
    logout: "/partner/logout",
    resetPassword: "/partner/reset-password/:uuid/:token/:timestamp",
    forgotPassword: "/partner/forgot-password",
  },
  error: "/E88iUJijnM",
} as const

import React from "react"

import { Outlet } from "react-router"

import { useLogoutMessaging } from "utils/hooks/useLogout"

const AppWrapper = () => {
  useLogoutMessaging()

  return <Outlet />
}

export default AppWrapper

import { AuthData } from "api/resources/users/types"
import { webengage } from "utils/webengage"

import { NetCore } from "./netcore"

type Service = "MoEngage" | "WebEngage" | "NetCore"

const allServices: Service[] = ["MoEngage", "WebEngage", "NetCore"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    },
    services = allServices
  ) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        webengage.user.setAttribute(
          "we_last_name",
          authInfo.user.lastName || ""
        )
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }

    try {
      if (services.includes("NetCore")) {
        NetCore.putAttribute("pk^customerid", authInfo.user.uuid)
        NetCore.putAttribute("FIRST_NAME", authInfo.user.firstName)
        NetCore.putAttribute("LAST_NAME", authInfo.user.lastName)
        NetCore.putAttribute("email", authInfo.user.email)
      }
    } catch (e) {
      console.warn("Error initialising user on NetCore", e)
    }
  },

  trackEvent: (eventName: string, data?: any, services = allServices) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
      console.warn(e)
    }

    try {
      if (services.includes("NetCore")) NetCore.logEvent(eventName, data)
    } catch (e) {
      console.warn(`Error tracking event on NetCore: ${eventName}`, data)
      console.warn(e)
    }
  },
}

import React, { useEffect, useState } from "react"

import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import metadata from "metadata.json"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { Link, useSearchParams } from "react-router-dom"

import api from "api"
import AuthLayout from "components/auth/school/AuthLayout"
import LoadingOverlay from "components/shared/LoadingOverlay"
import ReactHelmet from "components/shared/ReactHelmet"
import { clearAuthInfo, getAuthInfo, saveAuthInfo } from "utils/auth"
import { Platforms, Product, SCHOOL_PLATFORM_URL } from "utils/constants"
import { handleErrors } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"

const useStyles = createUseStyles({
  root: {
    width: 358,
    maxWidth: "100%",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
})

type FormData = {
  email: string
  password: string
}

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const [loading, toggleLoading] = useToggle(true)
  const [loadingMessage, setLoadingMessage] = useState("")

  const classes = useStyles()
  const [searchParams] = useSearchParams()

  const openLink = (code: string) => {
    const redirectUrl = searchParams.get("redirect-url")
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      url.searchParams.append("code", code)
      window.location.href = url.href
    } else {
      console.error("> redirect-url not found. Redirecting to school platform")
      const url = new URL(SCHOOL_PLATFORM_URL)
      url.searchParams.append("code", code)
      window.location.href = url.href
    }
  }

  const generateAuthCode = async () => {
    toggleLoading(true)
    setLoadingMessage("You will be redirected shortly")

    const res = await api.users.generateAuthCode({
      data: { platform: Platforms.school },
    })
    if (res.isSuccessful) {
      const { code } = res.data
      openLink(code)
    } else {
      toggleLoading(false)
      setLoadingMessage("")
      if (res.errors.message) console.log(res.errors.message)
    }
  }

  const onSubmit = handleSubmit(async data => {
    // Logout the user if they are already logged in
    if (getAuthInfo()) {
      await api.users.logout()
      clearAuthInfo()
    }

    const res = await api.users.login({
      data: { ...data, product: Product.school },
    })
    if (res.isSuccessful) {
      saveAuthInfo({ ...res.data, platform: Platforms.school })
      await generateAuthCode()
    } else {
      handleErrors(setError, res.errors)
    }
  })

  useEffect(() => {
    const handleLogin = () => {
      if (getAuthInfo()) {
        if (getAuthInfo()?.platform === Platforms.school) {
          return generateAuthCode()
        }
      }
      toggleLoading(false)
    }
    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  return (
    <>
      <ReactHelmet data={metadata.school.login} />
      <AuthLayout>
        <Typography component="h1" variant="title2">
          Log In.
        </Typography>
        <Typography className="mb-4" color="onSurface.500" variant="subtitle2">
          Hire Skilled Teachers for Free!
        </Typography>
        <form
          className={clsx("flex flex-col gap-2 mt-3 mx-auto", classes.root)}
          onSubmit={onSubmit}
        >
          <TextField
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            label="email address"
            type="email"
            fullWidth
            {...register("email", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            autoComplete="password"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            label="password"
            type="password"
            fullWidth
            {...register("password", {
              required: { value: true, message: "Required" },
            })}
          />
          <Button
            className="mt-3"
            loading={isSubmitting}
            type="submit"
            fullWidth
          >
            Sign in
          </Button>
        </form>
        <div className="mt-2 flex justify-center">
          <Button
            component={Link}
            to={routes.school.forgotPassword}
            variant="link"
          >
            Forgot Password
          </Button>
        </div>
      </AuthLayout>
    </>
  )
}

export default Login
